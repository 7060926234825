import React from "react";
import PhoneSVG from "../../images/symbols/phone.svg";
import EmailSVG from "../../images/symbols/envelope.svg";
import LocationSVG from "../../images/symbols/location.svg";
import WebSVG from "../../images/symbols/web.svg";
import styled from "styled-components";

const ContactContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: ${(props) => props.titleGap || "32px"};

   .contact-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
   }

   .contact-detail {
      display: flex;
      gap: 10px;
      width: 100%;
   }

   .contact-detail svg {
      flex-shrink: 0;
   }

   .contact-detail p,
   .contact-detail a {
      color: ${(props) => props.textColor || "var(--text-muted-inverted)"};
      line-height: 20px;
   }

   .contact-detail a {
      text-decoration: none;
      font-size: 16px;
   }

   .contact-detail:last-child a {
      cursor: pointer;
   }

   .contact-detail:last-child a:hover {
      text-decoration: underline;
   }
`;

export default function ContactsInfo({
   sectionTitle,
   titleGap,
   textColor,
   ...rest
}) {
   return (
      <ContactContainer titleGap={titleGap} textColor={textColor} {...rest}>
         <p style={{ fontWeight: 700 }}>{sectionTitle}</p>
         <div className="contact-info-wrapper">
            <div className="contact-detail">
               <PhoneSVG />
               <p>+351 217 921 260</p>
            </div>
            <div className="contact-detail">
               <EmailSVG />
               <p>connect@nearshore.pt</p>
            </div>
            <div className="contact-detail">
               <LocationSVG />
               <p>R. Soeiro Pereira Gomes - Lot 1, 4B, 1600-198 Lisbon</p>
            </div>
            <div className="contact-detail">
               <WebSVG />
               <a
                  href="https://caixamagica.pt/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  caixamagica.pt
               </a>
            </div>
         </div>
      </ContactContainer>
   );
}
