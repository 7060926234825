import React, { useEffect, useState } from "react";
import NavBar from "./navbar";
import { createGlobalStyle } from "styled-components";
import "../styles/global.css";
import { Component } from "react";
import "@fontsource/red-hat-display";
import "@fontsource/red-hat-display/900.css";
import "@fontsource/red-hat-display/700.css";
import "@fontsource/red-hat-display/600.css";
import "@fontsource/red-hat-display/500.css";
import "@fontsource/red-hat-display/400.css";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const GlobalStyles = createGlobalStyle`
  html {
    --accent-primary: #2F79F3;
    --accent-primary-05: #2F79F30D;
    --accent-primary-10: #2F79F31A;
    --accent-primary-dark: #2661C2;
    --accent-muted: #93A0F7;
    
    --medium-bg: #F2F2F8;
    --light-bg: #FFFFFF;
    --light-bg-opacity-30: #FFFFFF4D;
    --light-bg-opacity-80: #FFFFFFCC;
    --dark-bg: #0B0F19;
    
    --gradient-bg: linear-gradient(90deg, #0b0f19 0%, #132246 100%);

    --text-primary: #000000;
    --text-inverted: #FFFFFF;
    --text-muted: #404655;
    --text-muted-inverted: #E6E7E9;
    --light-bg-opacity-20: #FFFFFF33;
    --light-bg-opacity-05: #FFFFFF0D;

    --screen-max-width: 1140px;
    --screen-margin: unset;

    display: table;
    width: 100%;
    max-width: 100%;
    //scroll-behavior: smooth;
    height: 100%;
  }

  ::-moz-placeholder {
  opacity: 1;
    }

  #___gatsby{
    height: 100%;
  }  

  #gatsby-focus-wrapper{
    height: 100%;
  }

  body {
    margin: 0;
    min-height: 100%;
    width: 100%;
    max-width: 100%;
    display: table-cell;
    font-family: "Red Hat Display", sans-serif;
    font-display: swap;
    overflow-x: hidden;
  }
  
  .MuiPickersDay-root,
  .MuiDayCalendar-weekDayLabel,
  .MuiPickersCalendarHeader-labelContainer {
    font-size: 10px !important;
    font-family: 'Red Hat Display', sans-serif !important;
  }

  p{
    font-size: 16px;
    margin: 0;
    line-height: 26px;
    font-weight: 400;
  }

  .tagline{
    letter-spacing: 1.6px;
    margin: 0;
  }

  h1 {
    font-size: 48px;
    font-weight: 900;
    margin: 0;
    line-height: 63.5px;
  }

  h2{
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    line-height: 53px;
  }

  h3{
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    line-height: 37px;
  }

  h4{
    font-size: 21px;
    font-weight: 700;
    margin: 0;
    line-height: 28px;
  }

  input{
    font-size: 1.6rem;
    margin: 0;
    line-height: 26px;
    font-family: "Red Hat Display", sans-serif;
  }

  textarea{
   font-family: "Red Hat Display", sans-serif;
  }

  @media only screen and (max-width: 1239px) {
      html {
         --screen-max-width: unset;
         --screen-margin: 0 32px;
      }
   }
   @media only screen and (max-width: 600px) {
      html {
         --screen-margin: 0 16px;
      }

      h1{
         font-size: 36px;
         line-height: 48px;
      }

      h2{
         font-size: 32px;
         line-height: 42px;
      }
   }
`;

const Layout = ({ children }) => {
   const breakpoints = useBreakpoint();
   const [isMobile, setIsMobile] = useState(null);

   useEffect(() => {
      setIsMobile(breakpoints.mobile);
   }, [breakpoints.mobile]);

   if (isMobile === null || isMobile === undefined) {
      return null; // Optionally render a loader during hydration
   }
   console.log(isMobile);

   return (
      <div
         style={{
            height: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
         }}
      >
         <GlobalStyles />
         <NavBar isMobile={isMobile} />
         {React.Children.map(children, (child) =>
            React.cloneElement(child, { isMobile })
         )}
      </div>
   );
};

export default Layout;
