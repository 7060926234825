import React from "react";
import styled from "styled-components";
import LinkedinSVG from "../../images/symbols/linkedin.svg";
import InstagramSVG from "../../images/symbols/instagram.svg";
import FacebookSVG from "../../images/symbols/facebook.svg";

const SocialMediaContainer = styled.div`
   display: flex;
   flex-direction: column;
   gap: ${(props) => props.titleGap || "32px"};

   .icons-container {
      display: flex;
      gap: 16px;
   }

   .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: ${(props) => (props.squareIcons ? "6px" : "50%")};
      background-color: ${(props) =>
         props.colorsInverted ? "var(--medium-bg)" : "#ffffff0f"};
      width: 40px;
      height: 40px;
      cursor: pointer;
   }

   svg {
      color: ${(props) =>
         props.colorsInverted ? "var(--text-primary)" : "var(--text-inverted)"};
   }
`;

export default function SocialMedia({
   squareIcons,
   titleGap,
   colorsInverted,
   ...rest
}) {
   return (
      <SocialMediaContainer
         squareIcons={squareIcons}
         titleGap={titleGap}
         colorsInverted={colorsInverted}
         {...rest}
      >
         <p style={{ fontWeight: 700 }}>Social Media</p>
         <div className="icons-container">
            <div className="icon-wrapper">
               <a
                  href="https://www.linkedin.com/company/caixa-magica-software/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <LinkedinSVG />
               </a>
            </div>
            <div className="icon-wrapper">
               <a
                  href="https://www.instagram.com/caixamagicasoftware/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <InstagramSVG />
               </a>
            </div>
            <div className="icon-wrapper">
               <a
                  href="https://www.facebook.com/CaixaMagicaSoftware"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <FacebookSVG />
               </a>
            </div>
         </div>
      </SocialMediaContainer>
   );
}
