import React from "react";
import styled, { css } from "styled-components";
import ArrowUpRightSVG from "../../images/symbols/arrowUpRight.svg";
import { Link } from "gatsby";

export const ButtonTypes = {
   PRIMARY: "primary",
   SECONDARY: "secondary",
   LINK: "link",
};

export const ButtonVariants = {
   BLACK: "black",
   WHITE: "white",
   PRIMARY_COLOR: "primary_color",
};

const types = {
   [ButtonTypes.PRIMARY]: {
      backgroundColor: "var(--accent-primary)",
      hoverBackgroundColor: "var(--accent-primary-dark)",
   },
   [ButtonTypes.SECONDARY]: {
      backgroundColor: "transparent",
      border: "1.5px solid var(--text-inverted)",
      hoverBackgroundColor: "var(--accent-primary-10)",
   },
   [ButtonTypes.LINK]: {
      backgroundColor: "transparent",
      padding: "0",
      fontWeight: "500",
   },
};

const variants = {
   [ButtonVariants.BLACK]: {
      color: "var(--text-primary)",
      hoverBackgroundColor: "var(--light-bg-opacity-30)",
   },
   [ButtonVariants.WHITE]: {
      color: "var(--text-primary)",
      backgroundColor: "var(--text-inverted)",
      hoverBackgroundColor: "var(--light-bg-opacity-80)",
   },
   [ButtonVariants.PRIMARY_COLOR]: {
      color: "var(--accent-primary)",
      border: "1.5px solid var(--accent-primary)",
   },
};

const StyleButton = styled.button(
   ({ type, variant, disabled }) => css`
      display: flex;
      align-items: center;
      font-family: "Red Hat Display", sans-serif;
      justify-content: center;
      padding: ${types[type]?.padding || "12px 24px"};
      cursor: pointer;
      border-radius: 6px;
      color: ${variants[variant]?.color || "var(--text-inverted)"};
      border: ${variants[variant]?.border || types[type]?.border || "none"};
      font-size: 1.6rem;
      font-weight: ${types[type]?.fontWeight || "700"};
      background-color: ${variants[variant]?.backgroundColor ||
      types[type]?.backgroundColor ||
      "var(--accent-primary)"};
      width: fit-content;
      white-space: nowrap;
      transition: background-color 0.3s ease;
      text-decoration: none;
      opacity: ${disabled ? "38% !important" : "100%"};
      cursor: ${disabled ? "not-allowed" : "pointer"};

      &:hover {
         background-color: ${variants[variant]?.hoverBackgroundColor ||
         types[type]?.hoverBackgroundColor ||
         ""};
      }

      &:focus-visible {
         outline: 4px solid var(--accent-muted);
      }

      a {
         text-decoration: none;
      }

      .buttonIconWrapper {
         display: flex;
         align-items: center;
         justify-content: center;
         margin-right: 10px;
         width: 20px;
         height: 20px;
      }
   `
);

const Button = ({
   content,
   type,
   variant,
   className,
   includeArrowLink = false,
   url,
   disabled = false,
   onClick,
   Icon,
   style,
   ...rest
}) => {
   const buttonContent = (
      <>
         {Icon && (
            <div className="buttonIconWrapper">
               <Icon />
            </div>
         )}
         {content}
         {includeArrowLink && <ArrowUpRightSVG />}
      </>
   );

   if (url) {
      const isInternal = /^\/(?!\/)/.test(url); // Check if the link is internal

      // Render internal links using Gatsby's Link component
      if (isInternal) {
         return (
            <StyleButton
               as={Link}
               to={url}
               variant={variant}
               type={type}
               className={className}
               disabled={disabled}
               onClick={onClick}
               style={style}
               {...rest}
            >
               {buttonContent}
            </StyleButton>
         );
      }

      // Render external links using <a> tag
      return (
         <StyleButton
            as="a"
            href={url}
            className={className}
            type={type}
            variant={variant}
            disabled={disabled}
            onClick={onClick}
            style={style}
            target="_blank"
            rel="noopener noreferrer"
            {...rest}
         >
            {buttonContent}
         </StyleButton>
      );
   }

   // If no URL is provided, render a regular button
   return (
      <StyleButton
         type={type}
         variant={variant}
         className={className}
         disabled={disabled}
         onClick={onClick}
         style={style}
         {...rest}
      >
         {buttonContent}
      </StyleButton>
   );
};

export default Button;
